import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      footerBg: file(relativePath: { eq: "footer-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const { footerBg } = data
  const date = new Date()
  return (
    <footer>
      <Img className="bg-image-fit" fluid={footerBg.childImageSharp.fluid} />
      <img
        alt=""
        className="footer-right-box"
        src="/img/footer-right-box.png"
      />
      <img
        alt=""
        className="footer-left-arrow"
        src="/img/footer-arrow-left.png"
      />
      <img
        alt=""
        className="footer-right-arrow"
        src="/img/footer-arrow-right.png"
      />
      <div className="footer-content-container">
        <div>
          <h4>
            <span>Let’s Collaborate on Your Project</span>
          </h4>
        </div>
        <div className="footer-form-container">
          <form
            action="/thank-you"
            name="footer-form"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="footer-form" />
            <input type="hidden" name="bot-field" />
            <div className="project-form-fields-container">
              <input name="name" required type="text" placeholder="Name" />
              <input name="email" required type="text" placeholder="Email" />
              <select name="options">
                <option value="Base Shape/Size">Solution Needed</option>
                <option value="Closet">Closet</option>
                <option value="Entertainment Center">
                  Entertainment Center
                </option>
                <option value="Garage">Garage</option>
                <option value="Pantries">Pantries</option>
                <option value="Wall Bed">Wall Bed</option>
                <option value="Laundry Room">Laundry Room</option>
                <option value="Commercial Cabinetry">
                  Commercial Cabinetry
                </option>
              </select>
            </div>
            <div className="project-form-submit-container">
              <input type="submit" value="Submit" />
            </div>
          </form>
        </div>
        <div className="footer-call-container">
          <h5>
            <a href="tel:7028965450">702-896-5450</a>
          </h5>
        </div>
        <div className="footer-logo-container">
          <a href="/">
            <img alt="" src="/img/footer-logo.svg" />
          </a>
        </div>
        <ul className="footer-links-container">
          <li style={{ color: "#6179a3" }}>
            © Copyright {date.getFullYear()}, Absolute Closets & Cabinetry, All
            Rights Reserved
          </li>
          <li>
            <a style={{ color: "#6179a3" }} href="/privacy-policy">
              Privacy Policy
            </a>
          </li>
          <li>
            <a style={{ color: "#6179a3" }} href="https://adtack.com">
              Las Vegas Digital Agency
            </a>
          </li>
        </ul>
        <p
          style={{
            color: "white",
            textAlign: "center",
            margin: 0,
            marginBottom: "12px",
          }}
        >
          NEVADA CONTRACTORS LICENSE #71680 BID LIMIT $950,000
        </p>
      </div>
    </footer>
  )
}
export default Footer
