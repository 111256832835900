import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Fade } from "react-reveal"
import CTAButton from "./CTAButton"
import { slide as Menu } from "react-burger-menu"
import HeaderInfo from "./HeaderInfo"
import "../components/burger-menu.css"

const Header = props => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      wpgraphql {
        menus(where: { id: 2 }) {
          nodes {
            menuItems {
              edges {
                node {
                  url
                  label
                }
              }
            }
          }
        }
      }
      headerBgImage: file(relativePath: { eq: "home-header-bg.jpg" }) {
        childImageSharp {
          id
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      secondaryHeaderBgImage: file(relativePath: { eq: "header-image.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const menu = data.wpgraphql.menus.nodes[0].menuItems.edges
  const { id, pageTitle, pageSubtitle, content } = props
  const { headerBgImage, secondaryHeaderBgImage } = data

  return (
    <header>
      <HeaderInfo menu={menu} id={id} />
      <div className={`header-content-container ${id === 42 ? "home" : ""}`}>
        {id === 42 ? (
          <>
            <div className="home-logo-container">
              <img src="/img/home-logo.svg" />
            </div>
          </>
        ) : (
          <Img
            className="bg-image-fit"
            fluid={secondaryHeaderBgImage.childImageSharp.fluid}
          />
        )}
        {id === 42 ? (
          <div className="home-title-container">
            <Fade left>
              <div className="home-title">
                <h1 className="page-title">{pageTitle}</h1>
                <h1 className="page-subtitle">Perfectly Designed.</h1>
              </div>
              <div className="home-title-links-container">
                <div className="home-title-links">
                  <Link to="/custom-solutions">Browse Services</Link>
                  <img src="/img/home-title-arrow.svg" />
                  <Link to="/project-gallery">Browse Gallery</Link>
                </div>
              </div>
            </Fade>
          </div>
        ) : (
          <div className="page-title-container">
            <h1 className="page-title">{pageTitle}</h1>
            <div className="arrow-container">
              <img src={"/img/arrow.png"} alt="" className="arrow" />
            </div>
            {
              <div style={content ? {} : { opacity: 0 }} class="content">
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            }
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
