import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Fade } from "react-reveal"
import SEO from "../components/seo"
import getPreview from "../components/getPreview"

function Gallery({ location }) {
  const [currentCategory, setCategory] = useState("All")
  const [modalImage, setModalImage] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [previewData, setPreviewData] = useState(null)

  const data = useStaticQuery(graphql`
    query GalleryQuery {
      galleryBg: file(relativePath: { eq: "gallery-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wpgraphql {
        pages(where: { id: 7 }) {
          edges {
            node {
              seo {
                title
                metaDesc
              }
              id
              title
              content
              subtitle {
                subtitle
              }
              closingHeader {
                header
                subheader
              }
              gallery {
                images {
                  category
                  image {
                    sourceUrl
                    imageFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  function handleImageClick(image) {
    setShowModal(true)
    setTimeout(() => {
      setModalImage(image)
    }, 0)
  }
  function handleModalClick() {
    setModalImage(null)
    setShowModal(false)
  }
  const { seo } = previewData || data.wpgraphql.pages.edges[0].node
  const { images } =
    (previewData && previewData.gallery) ||
    data.wpgraphql.pages.edges[0].node.gallery
  const { content, title, subtitle, closingHeader } =
    previewData || data.wpgraphql.pages.edges[0].node
  const { galleryBg } = data
  const categories = [
    "All",
    ...new Set(images.map(image => image.category).sort()),
  ]
  useEffect(() => {
    if (location.hash) {
      const hash = location.hash
        .toLowerCase()
        .trim()
        .replace("#", "")
      categories.forEach(category => {
        if (
          category
            .toLowerCase()
            .trim()
            .replace(" ", "-") === hash
        ) {
          setCategory(category)
        }
      })
    }
    getPreview(`query MyQuery {
      pages(where: { id: 7 }) {
        nodes {
          revisions {
             nodes {
              seo {
                title
                metaDesc
              }
              id
              title
              content
              subtitle {
                subtitle
              }
              closingHeader {
                header
                subheader
              }
              gallery {
                images {
                  category
                  image {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
    }
}`).then(preview => preview && setPreviewData(preview))
  }, [])
  return (
    <Layout
      content={content}
      pageSubtitle={subtitle.subtitle}
      pageTitle={title}
    >
      <SEO description={seo.metaDesc} title={seo.title} />
      <section class="content-container gallery">
        <div
          style={{
            display: showModal ? "flex" : "none",
            opacity: modalImage ? "1" : "0",
          }}
          className="modal-image-container"
          onClick={handleModalClick}
        >
          <div className="modal-image">
            {modalImage && <img src={modalImage} />}
          </div>
        </div>

        <div class="gallery-container">
          <div class="gallery-categories-container">
            <Img
              className="bg-image-fit"
              fluid={galleryBg.childImageSharp.fluid}
            />
            <div id="gallery-categories">
              {categories.map(category => {
                return (
                  <button
                    onClick={() => setCategory(category)}
                    class={`category-button${
                      currentCategory === category ? " active" : ""
                    }`}
                  >
                    <span class="category-button-shapes"></span>
                    <span class="category-button-text">{category}</span>
                  </button>
                )
              })}
            </div>
          </div>
          <div class="gallery-images-container">
            <div id="gallery-images">
              {images
                .filter(
                  image =>
                    currentCategory === "All" ||
                    currentCategory === image.category
                )
                .map(image => {
                  return (
                    <Fade>
                      <div
                        onClick={() =>
                          handleImageClick(
                            previewData
                              ? image.image.sourceUrl
                              : image.image.imageFile.childImageSharp.fluid.src
                          )
                        }
                      >
                        {previewData ? (
                          <img src={image.image.sourceUrl} />
                        ) : (
                          <Img
                            fluid={image.image.imageFile.childImageSharp.fluid}
                          />
                        )}
                      </div>
                    </Fade>
                  )
                })}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Gallery
