import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { Fade } from "react-reveal"
import getPreview from "../components/getPreview"

const IndexPage = () => {
  const [serviceSelected, selectService] = useState(0)
  const [modalImage, setModalImage] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [previewData, setPreviewData] = useState(null)

  const data = useStaticQuery(graphql`
    query HomePageQuery {
      wpgraphql {
        pages(where: { id: 42 }) {
          edges {
            node {
              id
              title
              seo {
                title
                metaDesc
              }
              home {
                section1 {
                  content
                  subtitle
                  title
                  link {
                    url
                    title
                  }
                }
                section2 {
                  contentBelowTitles
                  subtitle
                  title
                  testimonial {
                    content
                    image {
                      sourceUrl
                      imageFile {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    name
                    title
                  }
                  link {
                    title
                    url
                  }
                  services {
                    content
                    title
                    backgroundImage {
                      sourceUrl
                      imageFile {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
                section3 {
                  content
                  subtitle
                  title
                  gallery {
                    image {
                      sourceUrl
                      imageFile {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                  link {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
      processBg: file(relativePath: { eq: "process-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      galleryBg: file(relativePath: { eq: "home-gallery-bg.png" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  function handleImageClick(image) {
    setShowModal(true)
    setTimeout(() => {
      setModalImage(image)
    }, 0)
  }
  function handleModalClick() {
    setModalImage(null)
    setShowModal(false)
  }
  useEffect(() => {
    getPreview(`query MyQuery {
  pages(where: { id: 42 }) {
    nodes {
      revisions {
         nodes {
          id
              title
              seo {
                title
                metaDesc
              }
              home {
                section1 {
                  content
                  subtitle
                  title
                  link {
                    url
                    title
                  }
                }
                section2 {
                  contentBelowTitles
                  subtitle
                  title
                  testimonial {
                    content
                    image {
                      sourceUrl
                      
                    }
                    name
                    title
                  }
                  link {
                    title
                    url
                  }
                  services {
                    content
                    title
                    backgroundImage {
                      sourceUrl
                      
                    }
                  }
                }
                section3 {
                  content
                  subtitle
                  title
                  gallery {
                    image {
                      sourceUrl
                      
                    }
                  }
                  link {
                    title
                    url
                  }
                }
              }
        }
      }
    }
}
}`).then(preview => preview && setPreviewData(preview))
  }, [])
  const { seo } = previewData || data.wpgraphql.pages.edges[0].node
  const pageTitle =
    (previewData && previewData.title) ||
    data.wpgraphql.pages.edges[0].node.title
  const { processBg, galleryBg } = data
  const { section1, section2, section3 } =
    (previewData && previewData.home) || data.wpgraphql.pages.edges[0].node.home
  return (
    <Layout id={42} pageTitle={pageTitle}>
      <SEO description={seo.metaDesc} title={seo.title} />
      <div
        style={{
          display: showModal ? "flex" : "none",
          opacity: modalImage ? "1" : "0",
        }}
        className="modal-image-container"
        onClick={handleModalClick}
      >
        <div className="modal-image">
          {modalImage && <img src={modalImage} />}
        </div>
      </div>
      <div class="home-personal-container">
        <div class="home-personal">
          <div class="home-about-us-container">
            <h3 class="home-about-us-title">{section1.title}</h3>
            <h3 class="home-about-us-subtitle">{section1.subtitle}</h3>
            <div class="home-about-us-content">
              <div dangerouslySetInnerHTML={{ __html: section1.content }} />
              <div style={{ textAlign: "center" }}>
                <Link class="cta" to={section1.link.url}>
                  {section1.link.title}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="process-container">
        <Img className="bg-image-fit" fluid={processBg.childImageSharp.fluid} />

        <div class="process-title-container">
          <img src="/img/process-shape.png" />
          <div class="process-title">
            <h1>{section2.title}</h1>
            <h2>{section2.subtitle}</h2>
          </div>
        </div>
        <div class="process-content-container">
          <div
            dangerouslySetInnerHTML={{ __html: section2.contentBelowTitles }}
          />

          <div class="process-button-container">
            <Fade top cascade>
              <div className="process-buttons">
                {section2.services.map((service, index) => {
                  const selected = serviceSelected === index
                  return (
                    <button
                      key={index}
                      onClick={() => selectService(index)}
                      class="process-button"
                    >
                      {selected ? (
                        previewData ? (
                          <img
                            className="process-button-bg"
                            src={service.backgroundImage.sourceUrl}
                          />
                        ) : (
                          <Img
                            className="process-button-bg"
                            fluid={
                              service.backgroundImage.imageFile.childImageSharp
                                .fluid
                            }
                          />
                        )
                      ) : null}
                      <span style={selected ? { color: "white" } : {}}>
                        {service.title}
                      </span>
                    </button>
                  )
                })}
              </div>
            </Fade>
          </div>

          <div
            className="process-content"
            dangerouslySetInnerHTML={{
              __html: section2.services[serviceSelected].content,
            }}
          />
          <div
            style={{ textAlign: "center", marginTop: "37px" }}
            class="circle-bg"
          >
            <Link
              class="cta"
              to={section2.link.url}
              style={{ color: "#404e66", background: "white" }}
            >
              {section2.link.title}
            </Link>
          </div>
        </div>
        <Fade top distance="100px">
          <div class="testimonial-container-container">
            <div class="testimonial-container">
              <div class="testimonial-flex-container">
                <div class="testimonial-content-container">
                  <div
                    className="testimonial"
                    dangerouslySetInnerHTML={{
                      __html: section2.testimonial.content,
                    }}
                  />
                  <div class="testimonial-person-container-container">
                    <div class="testimonial-person-container">
                      <h4>{section2.testimonial.name}</h4>
                      <h5>{section2.testimonial.title}</h5>
                      <img src="/img/5-stars.svg" />
                    </div>
                  </div>
                </div>
             
              </div>
            </div>
          </div>
        </Fade>
      </section>
      <section class="home-gallery-container">
        <Img className="bg-image-fit" fluid={galleryBg.childImageSharp.fluid} />

        <div class="home-gallery-content-container">
          <div class="home-gallery-title-container">
            <h1>{section3.title}</h1>
          </div>

          <div dangerouslySetInnerHTML={{ __html: section3.content }} />
        </div>
        <Fade bottom distance="100px">
          <div>
            <div class="home-gallery-images-container">
              <div>
                {section3.gallery.slice(0, 3).map(image => {
                  return (
                    <div
                      onClick={() =>
                        handleImageClick(
                          previewData
                            ? image.image.sourceUrl
                            : image.image.imageFile.childImageSharp.fluid.src
                        )
                      }
                    >
                      {previewData ? (
                        <img src={image.image.sourceUrl} />
                      ) : (
                        <Img
                          fluid={image.image.imageFile.childImageSharp.fluid}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
              <div>
                {section3.gallery.slice(3, 5).map(image => {
                  return (
                    <div
                      onClick={() =>
                        handleImageClick(
                          previewData
                            ? image.image.sourceUrl
                            : image.image.imageFile.childImageSharp.fluid.src
                        )
                      }
                    >
                      {previewData ? (
                        <img src={image.image.sourceUrl} />
                      ) : (
                        <Img
                          fluid={image.image.imageFile.childImageSharp.fluid}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
              <div>
                {section3.gallery.slice(5, 8).map(image => {
                  return (
                    <div
                      onClick={() =>
                        handleImageClick(
                          previewData
                            ? image.image.sourceUrl
                            : image.image.imageFile.childImageSharp.fluid.src
                        )
                      }
                    >
                      {previewData ? (
                        <img src={image.image.sourceUrl} />
                      ) : (
                        <Img
                          fluid={image.image.imageFile.childImageSharp.fluid}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </Fade>
        <div class="gallery-cta-container">
          <Link
            to={section3.link.url}
            style={{ background: "#404e66" }}
            class="cta"
          >
            {section3.link.title}
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
