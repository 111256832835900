import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import getPreview from "../components/getPreview"

function Sitemap() {
  const [previewData, setPreviewData] = useState(null)
  const data = useStaticQuery(graphql`
    query sitemapQuery {
      customSolutionsBg: file(relativePath: { eq: "process-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wpgraphql {
        pages(where: { id: 1004 }) {
          edges {
            node {
              seo {
                title
                metaDesc
              }
              title
              subtitle {
                subtitle
              }
              content
              closingHeader {
                header
                subheader
              }
            }
          }
        }
      }
    }
  `)
  useEffect(() => {
    getPreview(`query MyQuery {
      pages(where: { id: 1004 }) {
        nodes {
          revisions {
             nodes {
              seo {
                title
                metaDesc
              }
              title
              subtitle {
                subtitle
              }
              content
              closingHeader {
                header
                subheader
              }
            }
          }
        }
    }
}`).then(preview => preview && setPreviewData(preview))
  }, [])
  const { seo } = previewData || data.wpgraphql.pages.edges[0].node
  const { customSolutionsBg } = data
  const contact = previewData || data.wpgraphql.pages.edges[0].node
  return (
    <Layout content={null} pageTitle={contact.title} pageSubtitle={null}>
      <SEO description={seo.metaDesc} title={seo.title} />
      <section className="content-container contact">
        <Img
          className="bg-image-fit"
          fluid={customSolutionsBg.childImageSharp.fluid}
        />

        <div className="repeatable-section-container-container">
          <div class="content-section">
            <div dangerouslySetInnerHTML={{ __html: contact.content }} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Sitemap
