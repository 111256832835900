import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { HamburgerSqueeze } from "react-animated-burgers"
import { Slide } from "react-reveal"

function HeaderInfo(props) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)
  const adjustCTAButton = e => {
    if (window.scrollY > 70 && !scrolled) {
      setScrolled(true)
    }
    if (window.scrollY <= 0) {
      setScrolled(false)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", adjustCTAButton)
    return () => {
      window.removeEventListener("scroll", adjustCTAButton)
    }
  }, [scrolled])
  const handleBurgerClick = () => {
    setMenuOpen(!menuOpen)
  }
  const { id, menu } = props
  return (
    <>
      <div style={{ height: scrolled ? "58.2px" : "0px" }}></div>
      <div
        style={scrolled ? { position: "fixed", top: "0px" } : {}}
        className="header-info"
      >
        <div className="header-info-schedule-container">
          <Link to="/contact">
            <img src="/img/schedule.png" />
          </Link>
        </div>
        {(id !== 42 || scrolled) && (
          <div className="header-info-logo-container">
            <Link to="/">
              <img src="/img/home-logo.svg" />
            </Link>
          </div>
        )}
        {!scrolled && (
          <>
            <div>
              <a
                href="https://goo.gl/maps/J8wE2FEabJpMo1o39"
                rel="noopener noreferrer"
                target="_blank"
              >
                Visit Our Showroon
              </a>{" "}
              &nbsp; 6754 Spencer St., Las Vegas, NV 89119
            </div>
            <div style={{ color: "#b96e60", fontSize: "25px" }}>&#8226;</div>
            <div>
              <a href="tel:7028965450">702-896-5450</a>
            </div>
          </>
        )}
        <Slide duration={500} when={menuOpen} cascade right>
          <ul
            style={{ display: menuOpen ? "block" : "none" }}
            className="mobile-menu-container"
          >
            {menu.map(item => {
              return (
                <li>
                  <Link to={item.node.url}>{item.node.label}</Link>
                </li>
              )
            })}
          </ul>
        </Slide>
        <HamburgerSqueeze
          className="burger-button"
          buttonColor="#b96e60"
          barColor="white"
          isActive={menuOpen}
          toggleButton={handleBurgerClick}
        />
      </div>
    </>
  )
}

export default HeaderInfo
