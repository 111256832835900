import React, { useEffect, useState } from "react"
import { Fade } from "react-reveal"
import { Link } from "gatsby"

function CTAClose(props) {
  return (
    <button onClick={props.onClick} id="close-cta-button">
      &times;
    </button>
  )
}

function CTAButton(props) {
  const [scrolled, setScrolled] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)
  const adjustCTAButton = e => {
    if (window.scrollY > 0 && !scrolled) {
      setScrolled(true)
    }
    if (window.scrollY <= 0) {
      setScrolled(false)
    }
  }
  useEffect(() => {
    if (props.id !== 42) {
      window.addEventListener("scroll", adjustCTAButton)
      return () => {
        window.removeEventListener("scroll", adjustCTAButton)
      }
    }
  }, [scrolled])
  function handleClick() {
    setButtonClicked(true)
    window.removeEventListener("scroll", adjustCTAButton)
  }
  return (
    <div
      style={{
        position: props.id === 42 || buttonClicked ? "relative" : "fixed",
      }}
      className="schedule-button-container"
    >
      <Fade when={scrolled} duration={500}>
        {props.id !== 42 && scrolled && !buttonClicked && (
          <CTAClose onClick={handleClick} />
        )}
      </Fade>

      <Link to="/contact">
        <img src="/img/schedule-button.png" />
      </Link>
    </div>
  )
}

export default CTAButton
