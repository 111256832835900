import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import getPreview from "../components/getPreview"

function Sitemap() {
  return (
    <Layout content={null} pageTitle={"404"} pageSubtitle={null}>
      <SEO description={null} title={"404"} />
      <section className="content-container contact">
        <div className="repeatable-section-container-container">
          <div class="content-section">
            <p style={{ textAlign: "center" }}>
              The page you're looking for doesn't exist.{" "}
              <a href="/">Click here</a> to return to the homepage.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Sitemap
