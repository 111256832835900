import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import getPreview from "../components/getPreview"

function ThankYou() {
  const [previewData, setPreviewData] = useState(null)
  const data = useStaticQuery(graphql`
    query thankYouQuery {
      customSolutionsBg: file(relativePath: { eq: "process-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wpgraphql {
        pages(where: { id: 1002 }) {
          edges {
            node {
              seo {
                title
                metaDesc
              }
              title
              subtitle {
                subtitle
              }
              content
              closingHeader {
                header
                subheader
              }
            }
          }
        }
      }
    }
  `)
  useEffect(() => {
    getPreview(`query MyQuery {
      pages(where: { id: 1002 }) {
        nodes {
          revisions {
             nodes {
              seo {
                title
                metaDesc
              }
              title
              subtitle {
                subtitle
              }
              content
              closingHeader {
                header
                subheader
              }
            }
          }
        }
    }
}`).then(preview => preview && setPreviewData(preview))
  }, [])
  const { seo } = previewData || data.wpgraphql.pages.edges[0].node
  const { customSolutionsBg } = data
  const contact = previewData || data.wpgraphql.pages.edges[0].node
  return (
    <Layout
      content={contact.content}
      pageTitle={contact.title}
      pageSubtitle={contact.subtitle.subtitle}
    >
      <SEO description={seo.metaDesc} title={seo.title} />
      <section className="content-container contact">
        <Img
          className="bg-image-fit"
          fluid={customSolutionsBg.childImageSharp.fluid}
        />

        <div className="repeatable-section-container-container">
          <div className="contact-container">
            <div style={{ flexBasis: "100%", margin: 0, height: "400px" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d806.2706546627095!2d-115.12768147213322!3d36.06708888010628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8c60cd99ca985%3A0x6ddf142928f76c5b!2sAbsolute%20Closets%20%26%20Cabinetry!5e0!3m2!1sen!2sus!4v1605234063233!5m2!1sen!2sus"
                width="100%"
                height="100%"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ThankYou
