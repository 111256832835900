import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql, useStaticQuery, Link } from "gatsby"
import SEO from "../components/seo"
import { Fade } from "react-reveal"

function Blog() {
  const data = useStaticQuery(graphql`
    query blogQuery {
      blogBg: file(relativePath: { eq: "blog-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      categoriesBg: file(relativePath: { eq: "gallery-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wpgraphql {
        pages(where: { id: 289 }) {
          edges {
            node {
              seo {
                title
                metaDesc
              }
              title
              subtitle {
                subtitle
              }
              content
              closingHeader {
                header
                subheader
              }
            }
          }
        }
        posts(first: 100) {
          nodes {
            id
            title
            excerpt
            slug
            date
            categories {
              nodes {
                id
                name
              }
            }
            featuredImage {
              altText
              title
              slug
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  function getTitleBgColor(index) {
    const i = index + 1
    return i % 2 === 0 ? "#b3b19f" : i % 3 === 0 ? "#b96e60" : "#404e66"
  }
  function getLinkBgColor(index) {
    const i = index + 1
    return i % 2 === 0 ? "#404e66" : i % 3 === 0 ? "#b3b19f" : "#b96e60"
  }

  function filterLogic(post) {
    if (currentCategoryType === "Frequent Categories") {
      return post.categories.nodes.some(category => {
        return category.name === currentCategory
      })
    }
    const date = new Date(post.date)
    const year = date.getFullYear() + ""
    const month = date.toLocaleString("default", { month: "long" })
    return year === currentDate.year && month === currentDate.month
  }

  const { seo } = data.wpgraphql.pages.edges[0].node
  const { blogBg, categoriesBg } = data
  const blog = data.wpgraphql.pages.edges[0].node
  const posts = data.wpgraphql.posts.nodes
  const categories = []
  posts.forEach(post => {
    return post.categories.nodes.forEach(category => {
      categories.push(category.name)
    })
  })
  const dates = {}
  posts.forEach(post => {
    const date = new Date(post.date)
    const year = date.getFullYear()
    const month = date.toLocaleString("default", { month: "long" })
    function pushMonth() {
      if (!dates[year].includes(month)) {
        dates[year].push(month)
      }
    }
    if (dates[year]) {
      pushMonth()
    } else {
      dates[year] = []
      pushMonth()
    }
  })
  const categoriesCleaned = ["All", ...new Set(categories.sort())]
  const [currentCategoryType, setCurrentCategoryType] = useState(
    "Frequent Categories"
  )
  const [currentCategory, setCurrentCategory] = useState("All")
  const [currentDate, setDate] = useState({ year: null, month: null })
  return (
    <Layout
      content={null}
      pageTitle={blog.title}
      pageSubtitle={blog.subtitle.subtitle}
    >
      <SEO description={seo.metaDesc} title={seo.title} />
      <section class="blogs-category-section">
        <Img
          className="bg-image-fit"
          fluid={categoriesBg.childImageSharp.fluid}
        />
        <div className="category-types-container">
          <div className="category-types">
            <button
              onClick={() => {
                setCurrentCategory("All")
                setDate({ year: null, month: null })
                setCurrentCategoryType("Frequent Categories")
              }}
              style={
                currentCategoryType !== "Frequent Categories"
                  ? { background: "#526c9a" }
                  : {}
              }
            >
              Frequent Categories
            </button>
            <button
              onClick={() => {
                setCurrentCategory("All")
                setDate({ year: null, month: null })
                setCurrentCategoryType("Archive")
              }}
              style={
                currentCategoryType !== "Archive"
                  ? { background: "#526c9a" }
                  : {}
              }
            >
              Archive
            </button>
          </div>
        </div>
        <div
          className={`blogs-category-links ${
            currentCategoryType === "Archive" ? "blogs-archive" : ""
          }`}
        >
          {currentCategoryType === "Frequent Categories"
            ? categoriesCleaned.map(category => {
                return (
                  <button
                    key={category}
                    onClick={() => setCurrentCategory(category)}
                    style={
                      currentCategory === category
                        ? { background: "#b3b19f" }
                        : {}
                    }
                  >
                    {category}
                  </button>
                )
              })
            : Object.keys(dates)
                .sort((a, b) => b - a)
                .map(year => {
                  return (
                    <div key={year}>
                      <h3 className="blogs-category-year-title">{year}</h3>
                      <div className="blogs-category-archive-links">
                        {dates[year].map(month => {
                          return (
                            <button
                              key={month}
                              onClick={() => {
                                setCurrentCategory(null)
                                setDate({ year, month })
                              }}
                              style={
                                currentDate.year === year &&
                                currentDate.month === month
                                  ? { background: "#b3b19f" }
                                  : {}
                              }
                            >
                              {month}
                            </button>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
        </div>
      </section>
      <section className="content-container custom-solutions">
        <Img className="bg-image-fit" fluid={blogBg.childImageSharp.fluid} />

        <div className="blogs-container">
          {posts
            .filter(post => currentCategory === "All" || filterLogic(post))
            .map((post, i) => {
              return (
                <div className="blogs-blog-container">
                  <Fade duration={500} distance="100px" top>
                    <>
                      <div class="blogs-blog">
                        <div className="blogs-blog-image-container">
                          {post.featuredImage && (
                            <Img
                              className="bg-image-fit"
                              fluid={
                                post.featuredImage.imageFile.childImageSharp
                                  .fluid
                              }
                            />
                          )}
                          <div className="blogs-blog-title-container">
                            <h2 style={{ background: getTitleBgColor(i) }}>
                              {post.title}
                            </h2>
                            <Link
                              style={{ background: getLinkBgColor(i) }}
                              to={`/${post.slug}`}
                            >
                              <img src="/img/blog-right-arrow.svg" />
                            </Link>
                          </div>
                        </div>
                        <div
                          className="blogs-blog-content-container"
                          dangerouslySetInnerHTML={{ __html: post.excerpt }}
                        />
                      </div>
                    </>
                  </Fade>
                </div>
              )
            })}
        </div>
      </section>
      <div class="conclusion-container">
        <h3 class="page-bottom-header">{blog.closingHeader.header}</h3>
        <h4 class="page-bottom-subheader">{blog.closingHeader.subheader}</h4>
      </div>
    </Layout>
  )
}

export default Blog
