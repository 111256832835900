import qs from "query-string"
import { createApolloFetch } from "apollo-fetch"

function getPreview(query) {
  if (window !== undefined) {
    const parsed = qs.parse(window.location.search)
    const nonce = parsed.nonce
    if (nonce) {
      const uri = "https://absolute2.wpengine.com/graphql"
      const apolloFetch = createApolloFetch({ uri })
      apolloFetch.use(({ request, options }, next) => {
        if (!options.headers) {
          options.headers = {}
        }
        options.headers["X-WP-Nonce"] = nonce
        options.credentials = "include"

        next()
      })
      return apolloFetch({ query })
        .then(result => {
          console.log(result)
          const preview = result.data.pages.nodes[0].revisions.nodes[0]
          return preview
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    }
  }
}

export default getPreview
