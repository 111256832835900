import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Fade } from "react-reveal"
import withPreview from "../components/getPreview"
import getPreview from "../components/getPreview"

class MemberSlider extends React.Component {
  render() {
    const {
      team,
      currentMemberIndex,
      setCurrentMember,
      previewData,
    } = this.props
    const teamLength = team.length
    const settings = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: team.length >= 5 ? 5 : team.length,
      slidesToScroll: 1,
      nextArrow: <img src="/img/next-arrow.svg" />,
      prevArrow: <img src="/img/prev-arrow.svg" />,
      afterChange: index => {
        setCurrentMember({ member: team[index], index })
      },
      responsive: [
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 460,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <Fade duration={500} distance="100px">
        <Slider
          ref={slider => (this.slider = slider)}
          className="member-image-slider-container"
          {...settings}
        >
          {team.map((member, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  this.slider.slickGoTo(index)
                  setCurrentMember({ member: team[index], index })
                }}
                className={`member-image-slider-image${
                  index === currentMemberIndex ? " active" : ""
                }`}
              >
                <div className="member-image-slider-info-container">
                  <h2 className="member-image-slider-name">{member.name}</h2>
                  <h3 className="member-image-slider-field">{member.field}</h3>
                </div>
              </div>
            )
          })}
        </Slider>
      </Fade>
    )
  }
}

function MemberInfo({ currentMember, previewData }) {
  return (
    <div className="member-info-container">
      <Fade
        appear={true}
        spy={currentMember.index}
        distance="100px"
        duration={500}
        delay={200}
        left
      >
        <div className="member-info-content-container">
          <h2 className="member-info-name">{currentMember.member.name}</h2>
          <h2 className="member-info-title">{currentMember.member.title}</h2>
          <div
            className="member-info-content"
            dangerouslySetInnerHTML={{ __html: currentMember.member.bio }}
          />
        </div>
      </Fade>
    </div>
  )
}

function WorkingWithUs() {
  const [modalImage, setModalImage] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [previewData, setPreviewData] = useState(null)
  const data = useStaticQuery(graphql`
    query workingWithUsQuery {
      customSolutionsBg: file(relativePath: { eq: "process-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      closerBg: file(relativePath: { eq: "blog-bg.jpg" }) {
        childImageSharp {
          id
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wpgraphql {
        pages(where: { id: 5 }) {
          edges {
            node {
              seo {
                title
                metaDesc
              }
              title
              subtitle {
                subtitle
              }
              content
              closingHeader {
                header
                subheader
              }
              workingWithUs {
                section1 {
                  title
                  content
                }
                section2 {
                  title
                  content
                }
                section3 {
                  title
                  content
                }
                section4 {
                  title
                  content
                }
                team {
                  bio
                  field
                  portfolio {
                    sourceUrl
                    imageFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  image {
                    id
                    sourceUrl
                    imageFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  name
                  title
                }
              }
            }
          }
        }
      }
    }
  `)
  function handleImageClick(image) {
    setShowModal(true)
    setTimeout(() => {
      setModalImage(image)
    }, 0)
  }
  function handleModalClick() {
    setModalImage(null)
    setShowModal(false)
  }
  useEffect(() => {
    getPreview(`query MyQuery {
      pages(where: { id: 5 }) {
        nodes {
          revisions {
             nodes {
              seo {
                title
                metaDesc
              }
              title
              subtitle {
                subtitle
              }
              content
              closingHeader {
                header
                subheader
              }
              workingWithUs {
                section1 {
                  title
                  content
                }
                section2 {
                  title
                  content
                }
                section3 {
                  title
                  content
                }
                section4 {
                  title
                  content
                }
                team {
                  bio
                  field
                  portfolio {
                    sourceUrl
                  }
                  image {
                    id
                    sourceUrl
                  }
                  name
                  title
                }
              }
            }
          }
        }
    }
}`).then(preview => preview && setPreviewData(preview))
  }, [])
  const { seo } = previewData || data.wpgraphql.pages.edges[0].node
  const { customSolutionsBg, closerBg } = data
  const workingWithUs = previewData || data.wpgraphql.pages.edges[0].node
  const {
    team,
    section1,
    section2,
    section3,
    section4,
  } = workingWithUs.workingWithUs
  const [currentMember, setCurrentMember] = useState({
    member: team[0],
    index: 0,
  })
  return (
    <Layout
      content={workingWithUs.content}
      pageTitle={workingWithUs.title}
      pageSubtitle={workingWithUs.subtitle.subtitle}
    >
      <SEO description={seo.metaDesc} title={seo.title} />
      <div
        style={{
          display: showModal ? "flex" : "none",
          opacity: modalImage ? "1" : "0",
        }}
        className="modal-image-container"
        onClick={handleModalClick}
      >
        <div className="modal-image">
          {modalImage && <img src={modalImage} />}
        </div>
      </div>
      <section className="content-container working-with-us">
        <Img
          className="bg-image-fit working-with-us-bg"
          fluid={customSolutionsBg.childImageSharp.fluid}
        />
        <MemberSlider
          previewData={previewData}
          setCurrentMember={setCurrentMember}
          currentMemberIndex={currentMember.index}
          team={team}
        />
        <MemberInfo previewData={previewData} currentMember={currentMember} />
        <Fade
          appear={true}
          spy={currentMember.index}
          top
          distance="200px"
          duration={500}
          cascade
        >
          <div id="portfolio" className="member-portfolio-container">
            {currentMember.member.portfolio.map((image, index) => {
              const length = currentMember.member.portfolio.length
              return (
                <div
                  key={index}
                  onClick={() =>
                    handleImageClick(
                      previewData
                        ? image.sourceUrl
                        : image.imageFile.childImageSharp.fluid.src
                    )
                  }
                  style={{
                    flexBasis:
                      length >= 3
                        ? "calc(33.333% - 8px)"
                        : length >= 2
                        ? "50%"
                        : "100%",
                  }}
                >
                  {previewData ? (
                    <img className="bg-image-fit" src={image.sourceUrl} />
                  ) : (
                    <Img
                      className="bg-image-fit"
                      fluid={image.imageFile.childImageSharp.fluid}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </Fade>
      </section>
    </Layout>
  )
}

export default WorkingWithUs
